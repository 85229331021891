import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationModal from "../Confirmation Modal/ConfirmationModal";
import NavigationButtons from "../Navigation Buttons/Navigation Buttons";
import { dashboardDataAPI, creatwNewApplicationIncreasedAPI } from "../../api";
import { BiBell } from "react-icons/bi";
import { AiOutlineExpand } from "react-icons/ai";
import NotificationsModal from "../Notifications Modal/Notifications Modal";
// import { RiArrowRightSLine } from "react-icons/ri";
import backgroundImage from "./bg_of_dashboard2.png";
import Loader from "../Loader/Loader";

const Dashboard = ({ onLogout }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [dashboardData, setDashboardData] = useState(null);
  const [showNotificationsModal, setShowNotificationsModal] = useState(false);

  useEffect(() => {
    // Fetch dashboard data when component mounts
    async function fetchDashboardData() {
      try {
        setLoading(true);

        const response = await dashboardDataAPI();
        setLoading(false);

        if (response?.data?.response?.response === true) {
          setDashboardData(response.data);
        } else {
          if (response?.data?.response?.error_msg) {
            toast.error(response?.data?.response?.error_msg);
          } else if (
            response.status === 200 &&
            response.data === "Missing JWT token"
          ) {
            // toast.error("Missing Details");
            // localStorage.clear();
            // onLogout();
            // if (onLogout) {
            //   navigate("/");
            // }
            // navigate("/");
            fetchDashboardData();
          }
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        setLoading(false);
      }
    }
    fetchDashboardData();
  }, []);

  const handleCreateNewApplication = async () => {
    setLoading(true);
    try {
      const response = await creatwNewApplicationIncreasedAPI();
      setLoading(false);
      if (response?.data?.response?.response === true) {
        const responseData = response?.data?.response?.data;
        const { pointer_id, portal_refrance_no } = responseData;
        const pointerData = { pointer_id, portal_refrance_no };
        navigate("/user/create_new_application/stage_1/occupation_details", {
          state: { pointerData },
        });
      } else {
        toast.error(
          response?.data?.error_msg || "Failed to create new application"
        );
      }
    } catch (error) {
      setLoading(false); // Set loading to false in case of an error
      console.error("Error creating new application:", error);
      toast.error("Failed to create new application");
    }
  };

  const handleIncompleteApplications = () => {
    navigate("/user/incomplete_applications");
  };

  const handleSubmittedApplications = () => {
    navigate("/user/submitted_applications");
  };

  const handleEmploymentPendingVerification = () => {
    navigate("/user/employment_verification_pending");
  };

  const handleForms = () => {
    navigate("/user/forms");
  };

  const handleUpdatePersonalDetails = () => {
    navigate("/user/update_profile");
  };

  const handleLogout = () => {
    setShowConfirmationModal(true);
  };

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleConfirmationYes = () => {
    localStorage.clear();
    onLogout();
    if (onLogout) {
      navigate("/");
    }
    navigate("/");

    toast.success("Logged out successfully!");
  };

  const handleConfirmationNo = () => {
    setShowConfirmationModal(false);
  };

  const handleNotificationsButton = () => {
    setShowNotificationsModal(true);
  };

  const accountType = localStorage.getItem("account_type");

  const showCreateNewApplicationTab =
    dashboardData?.response?.data?.create_application;

  const showIncompleteApplicationTabTab =
    dashboardData?.response?.data?.incompleted_application_operation;

  useEffect(() => {
    localStorage.setItem(
      "showCreateNewApplicationTab",
      showCreateNewApplicationTab
    );

    // if (accountType === "Applicant" && showCreateNewApplicationTab === true) {
    //   localStorage.setItem("showCreateNewApplicationTab", true);
    // } else {
    //   localStorage.setItem("showCreateNewApplicationTab", false);
    // }

    localStorage.setItem(
      "showIncompleteApplicationTabTab",
      showIncompleteApplicationTabTab
    );

    // if (
    //   accountType === "Applicant" &&
    //   showIncompleteApplicationTabTab === true
    // ) {
    //   localStorage.setItem("showIncompleteApplicationTabTab", showIncompleteApplicationTabTab);
    // } else {
    //   localStorage.setItem("showIncompleteApplicationTabTab", false);
    // }

    if (dashboardData?.response?.data?.email_verifications !== 0) {
      localStorage.setItem("showEmploymentVerification", false);
    } else {
      localStorage.setItem("showEmploymentVerification", true);
    }
  }, []);

  return (
    <>
      <Row>
        {/* <Col>
          <Button
            className="back-button"
            variant=""
            onClick={handleNotificationsButton}
            style={{
              // backgroundColor: "#f0f8ff",
              color: "#055837",
              marginLeft: "25px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <BiBell className="me-2" />
            Notifications
            <AiOutlineExpand className="ms-2" />
          </Button>
        </Col> */}

        <Col>
          <NavigationButtons
            handleUpdatePersonalDetails={handleUpdatePersonalDetails}
            handleLogout={handleLogout}
          />
        </Col>
      </Row>

      <hr className="mb-2 thick-hr" />

      {/* <div
      // style={{
      //   backgroundImage: `url(${backgroundImage})`,
      //   backgroundSize: "cover",
      //   backgroundRepeat: "no-repeat",
      //   backgroundPosition: "center",
      //   minHeight: "auto",
      // }}
    > */}



      {/* <div className="d-flex justify-content-center align-items-center " style={{marginTop:"50px"}}> */}

      <div
  className="d-flex justify-content-center align-items-center"
  style={{
    // minHeight: "100%",
    // height: "auto",

    // minHeight: "100vh",
    // height: "1vh",
    height: "75vh",
    
    margin: 0,
  }}
>


        <Container className="mt-1 mb-1">


          {loading && (
            <div className="d-flex justify-content-center mt-3 mb-3">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )}

          {/* {loading && (
<Loader />
        )} */}

          {showCreateNewApplicationTab && (
            <Row
              className={`justify-content-center ${
                window.innerWidth < 576 ? "align-items-center" : ""
              }`}
              // style={{ marginBottom: "10px", marginTop: "15px" }}
              style={{ marginBottom: "10px", marginTop: "0px" }}

            >
              <Col md={5}>
                <Button
                  className="w-100 mb-2 d-flex align-items-center hover-effect"
                  onClick={handleCreateNewApplication}
                  style={{
                    height: "70px",
                    backgroundColor: "#055837",
                    color: "white",
                    fontSize: "20px",
                    padding: "0",
                    outline: "none",
                    boxShadow: "none",
                  }}
                >
                  <div
                    className="hover-container hover-number-container d-flex justify-content-center align-items-center"
                    style={{
                      width: "48px",
                      height: "100%",
                      backgroundColor: "#ffcc01",
                      borderRadius: "3px 5px 5px 3px",
                      margin: "0",
                      fontSize: "22px",
                    }}
                  >
                    <div style={{ color: "#055837" }}>
                      <i className="bi vishal_ic bi-align-middle"></i>
                    </div>
                  </div>

                  <div
                    className="flex-grow-1"
                    style={{ marginLeft: "15px", textAlign: "left" }}
                  >
                    Create New Application
                  </div>
                </Button>
              </Col>
            </Row>
          )}

          {showIncompleteApplicationTabTab && (
            <Row
              className="justify-content-center"
              style={{ marginBottom: "10px" }}
            >
              <Col md={5}>
                <Button
                  // variant="warning"
                  className="w-100 mb-2 d-flex align-items-center hover-effect"
                  onClick={handleIncompleteApplications}
                  style={{
                    height: "70px",
                    backgroundColor: "#055837",
                    color: "white",
                    fontSize: "20px",
                    padding: "0",
                    position: "relative",
                    outline: "none",
                    boxShadow: "none",
                  }}
                >
                  <div
                    className="hover-container hover-number-container d-flex justify-content-center align-items-center"
                    style={{
                      width: "48px",
                      height: "100%",
                      backgroundColor: "#ffcc01",
                      borderRadius: "3px 5px 5px 3px",
                      margin: "0",
                      fontSize: "22px",
                    }}
                  >
                    <div style={{ color: "#055837" }}>
                      {/* 📝 */}

                      <i class="bi vishal_ic bi-pencil-square"></i>
                    </div>
                  </div>

                  <div
                    className="flex-grow-1"
                    style={{ marginLeft: "15px", textAlign: "left" }}
                  >
                    Incomplete Applications
                  </div>

                  <div
                    className="hover-container hover-number-container"
                    style={{
                      width: "48px",
                      height: "100%",
                      backgroundColor: "#ffcc01",
                      borderRadius: "5px 3px 3px 5px",
                      margin: "0",
                      fontSize: "25px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#055837",
                      position: "absolute",
                      right: "0",
                      top: "0",
                    }}
                  >
                    <div>
                      {dashboardData?.response?.data?.incompleted_applications}
                    </div>
                  </div>
                </Button>
              </Col>
            </Row>
          )}

          <Row
            className="justify-content-center"
            style={{ marginBottom: "10px" }}
          >
            <Col md={5}>
              <Button
                // variant="warning"
                className="w-100 mb-2 d-flex align-items-center hover-effect"
                onClick={handleSubmittedApplications}
                style={{
                  height: "70px",
                  backgroundColor: "#055837",
                  color: "white",
                  fontSize: "20px",
                  padding: "0",
                  position: "relative",
                  outline: "none",
                  boxShadow: "none",
                }}
              >
                <div
                  className="hover-container hover-number-container d-flex justify-content-center align-items-center"
                  style={{
                    width: "48px",
                    height: "100%",
                    backgroundColor: "#ffcc01",
                    borderRadius: "3px 5px 5px 3px",
                    margin: "0",
                    fontSize: "22px",
                  }}
                >
                  <div style={{ color: "#055837" }}>
                    {/* 📄 */}

                    <i class="bi vishal_ic bi-stickies-fill "></i>
                  </div>
                </div>

                <div
                  className="flex-grow-1"
                  style={{ marginLeft: "15px", textAlign: "left" }}
                >
                  Submitted Applications
                </div>

                <div
                  className="hover-container hover-number-container"
                  style={{
                    width: "48px",
                    height: "100%",
                    backgroundColor: "#ffcc01",
                    borderRadius: "5px 3px 3px 5px",
                    margin: "0",
                    fontSize: "25px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#055837",
                    position: "absolute",
                    right: "0",
                    top: "0",
                  }}
                >
                  <div>
                    {dashboardData?.response?.data?.submitted_applications}
                  </div>
                </div>
              </Button>
            </Col>
          </Row>

          {dashboardData?.response?.data?.email_verifications !== 0 && (
            <Row
              className="justify-content-center"
              style={{ marginBottom: "10px" }}
            >
              <Col md={5}>
                <Button
                  // variant="warning"
                  className="w-100 mb-2 d-flex align-items-center hover-effect"
                  onClick={handleEmploymentPendingVerification}
                  style={{
                    height: "70px",
                    backgroundColor: "#055837",
                    color: "white",
                    fontSize: "20px",
                    padding: "0",
                    position: "relative",
                    outline: "none",
                    boxShadow: "none",
                  }}
                >
                  <div
                    className="hover-container hover-number-container d-flex justify-content-center align-items-center blink-animation"
                    style={{
                      width: "48px",
                      height: "100%",
                      backgroundColor: "#ffcc01",
                      borderRadius: "3px 5px 5px 3px",
                      margin: "0",
                      fontSize: "40px",
                    }}
                  >
                    <div style={{ color: "#055837" }}>
                      <span className="vishal_ic">!</span>
                    </div>
                  </div>

                  <div
                    className="flex-grow-1"
                    style={{ marginLeft: "15px", textAlign: "left" }}
                  >
                    Employment Verification Pending
                  </div>

                  <div
                    className="hover-container hover-number-container"
                    style={{
                      width: "48px",
                      height: "100%",
                      backgroundColor: "#ffcc01",
                      borderRadius: "5px 3px 3px 5px",
                      margin: "0",
                      fontSize: "25px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#055837",
                      position: "absolute",
                      right: "0",
                      top: "0",
                    }}
                  >
                    <div>
                      {dashboardData?.response?.data?.email_verifications}
                    </div>
                  </div>
                </Button>
              </Col>
            </Row>
          )}

          <Row
            className="justify-content-center"
            style={{ marginBottom: "10px" }}
          >
            <Col md={5}>
              <Button
                // variant="warning"
                className="w-100 mb-2 d-flex align-items-center hover-effect"
                onClick={handleForms}
                style={{
                  height: "70px",
                  backgroundColor: "#055837",
                  color: "white",
                  fontSize: "20px",
                  padding: "0",
                  outline: "none",
                  boxShadow: "none",
                }}
              >
                <div
                  className="hover-container hover-number-container d-flex justify-content-center align-items-center"
                  style={{
                    width: "48px",
                    height: "100%",
                    backgroundColor: "#ffcc01",
                    borderRadius: "3px 5px 5px 3px",
                    margin: "0", // Ensure no margin on the container
                    fontSize: "22px",
                  }}
                >
                  <div style={{ color: "#055837" }}>
                    {/* 🗃️ */}
                    <i class="bi vishal_ic  bi-folder2"></i>
                  </div>
                </div>

                <div
                  className="flex-grow-1"
                  style={{ marginLeft: "15px", textAlign: "left" }}
                >
                  Forms
                </div>
              </Button>
            </Col>
          </Row>

          <ConfirmationModal
            show={showConfirmationModal}
            onHide={handleConfirmationNo}
            onConfirm={handleConfirmationYes}
            title="Confirm Logout ?"
            message="Are you sure you want to log out?"
          />

          <NotificationsModal
            showNotificationsModal={showNotificationsModal}
            closeNotificationsModal={() => setShowNotificationsModal(false)}
          />
        </Container>
      </div>
    </>
  );
};

export default Dashboard;
